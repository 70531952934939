import type { ThemeOverride } from '@chakra-ui/react';
import { fontFamily, fontSize, lineHeight } from '@ifixit/primitives';

export const typography: ThemeOverride['typography'] = {
   fonts: {
      body: fontFamily.sansSystem,
      heading: fontFamily.sansSystem,
      mono: fontFamily.monoSystem,
   },

   fontSizes: {
      ...fontSize,
      '3xs': '0.45rem',
      '2xs': '0.625rem',
      xs: '0.75rem',
   },

   fontWeights: {
      hairline: 100,
      thin: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
   },

   lineHeights: {
      ...lineHeight,
      base: lineHeight.normal,
   },
};
