// We want to funnel all ChakraProvider instances through this component
// so that we can control the Provider props from a single location.

/* eslint-disable react/forbid-elements */

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { theme } from '@ifixit/carpenter-theme';

export const extendedTheme = extendTheme(theme);
let isChakraInitialized: boolean = false;

export function ChakraDefaultProvider({ children, theme, ...props }: any) {
   /* eslint-disable-next-line no-restricted-syntax */
   const disableGlobalStyle = isChakraInitialized;
   isChakraInitialized = true;

   const bypassIfixitTheme = theme === false;
   const themeToUse = theme || extendedTheme;

   return (
      <ChakraProvider
         {...props}
         theme={bypassIfixitTheme ? undefined : themeToUse}
         resetCSS={false}
         disableGlobalStyle={disableGlobalStyle}
      >
         {children}
      </ChakraProvider>
   );
}
