// These styles are applied globally to any page that renders Chakra components. Modify only when necessary, and with great care.

export const styles = {
   global: {
      // Override chakra light theme border color
      ':root[data-theme=light]': {
         '--chakra-colors-chakra-border-color': 'var(--chakra-colors-gray-300, #d1d5db)',
      },
   },
};

export default styles;
