import { ComponentStyleConfig } from '@chakra-ui/react';

const Tab: ComponentStyleConfig = {
   parts: ['tablist', 'tab'],
   variants: {
      line: {
         tablist: {
            borderColor: 'gray.200',
         },
         tab: {
            borderColor: 'gray.200',
            borderRadius: '0',
            color: 'gray.500',
            fontWeight: 'bold',
         },
      },
   },
};

export default Tab;
