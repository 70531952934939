import type { ThemeOverride } from '@chakra-ui/react';

export const space: ThemeOverride['space'] = {
   px: '1px',
   0.5: '2px',
   1: '4px',
   1.5: '6px',
   2: '8px',
   2.5: '10px',
   3: '12px',
   3.5: '14px',
   4: '16px',
   4.5: '18px',
   5: '20px',
   6: '24px',
   7: '28px',
   7.5: '30px',
   8: '32px',
   9: '36px',
   10: '40px',
   12: '48px',
   14: '56px',
   16: '64px',
   20: '80px',
   24: '96px',
   28: '112px',
   32: '128px',
   36: '144px',
   40: '160px',
   44: '176px',
   48: '192px',
   52: '208px',
   56: '224px',
   60: '240px',
   64: '256px',
   72: '288px',
   80: '304px',
   96: '384px',
};
