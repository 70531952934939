import { ComponentStyleConfig } from '@chakra-ui/react';

const Tooltip: ComponentStyleConfig = {
   baseStyle: {
      fontSize: 'md',
   },
   defaultProps: {
      openDelay: 50,
      shouldWrapChildren: true,
      closeOnMouseDown: true,
   },
};

export default Tooltip;
