import { ComponentStyleConfig } from '@chakra-ui/react';

const Link: ComponentStyleConfig = {
   sizes: {
      sm: {
         fontSize: 'md',
      },
      md: {
         fontSize: 'lg',
      },
      lg: {
         fontSize: 'xl',
      },
   },
   variants: {
      brand: {
         color: 'brand.500',
         _hover: {
            textDecoration: 'none',
         },
         _visited: {
            color: 'brand.500',
         },
      },
      underline: {
         textDecoration: 'underline',
         _hover: {
            textDecoration: 'underline',
         },
      },
   },
};

export default Link;
