import { ComponentStyleConfig } from '@chakra-ui/react';

const Badge: ComponentStyleConfig = {
   baseStyle: {
      borderRadius: 'md',
      boxShadow: 'none',
      display: 'inline-flex',
      fontSize: 'md',
      fontWeight: 'semibold',
      px: 1.5,
      py: 1,
      placeItems: 'center',
      textTransform: 'unset',
   },
   defaultProps: {
      variant: 'outline',
   },
   sizes: {
      tiny: {
         fontSize: '10px',
         height: '20px',
      },
   },
   variants: {
      outline: props => {
         const schemeColors = props.theme.colors[props.colorScheme];
         return {
            bg: schemeColors[100],
            color: schemeColors[700],
            borderColor: schemeColors[300],
         };
      },
   },
};

export default Badge;
