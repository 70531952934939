import { ComponentStyleConfig } from '@chakra-ui/react';

const Menu: ComponentStyleConfig = {
   parts: ['button', 'list', 'item', 'groupTitle', 'command', 'divider'],
   baseStyle: {
      list: {
         boxShadow: 'lg',
      },
   },
};

export default Menu;
